<template>
    <div class="surveyWarp">
        <fm-generate-form :data="jsonData" :edit="false"  :remote="remoteFuncs" :value="editData" ref="generateForm">

            <template slot="linSpe" slot-scope="scope">
                <!-- 自定义区域 -->
                <!-- use v-model="scope.model.linSpe" to bind data -->
                <div class="linSpe"></div>
            </template>
            <template slot="cuninfo" slot-scope="scope">
                <!--  -->
                <!-- use v-model="scope.model.cuninfo" to bind data -->
                <p class="title"><i></i>村情信息</p>
            </template>

            <template slot="provinces" slot-scope="scope">
                <!-- 自定义区域 -->
                <!-- use v-model="scope.model.provinces" to bind data -->
                <div class="ml">
                    <div >
                        <p>省市区县<i class="star"></i><b>:</b></p>
                        <van-field

                                   disabled
                                   autosize
                                   input-align="right"

                                   v-model=" scope.model.provinces "
                                   label=""
                                   placeholder=""
                                   ></van-field>
                    </div>
                </div>

            </template>

            <template slot="country" slot-scope="scope">
                <!-- 自定义区域 -->
                <!-- use v-model="scope.model.country" to bind data -->
                <div class="ml">
                    <div>
                        <p>乡镇村<i class="star"></i><b>:</b></p>
                        <van-field
                                disabled
                                   autosize
                                   input-align="right"

                                   v-model="scope.model.country"
                                   label=""
                                   placeholder=""
                                   ></van-field>
                    </div>

                </div>

            </template>

            <template slot="gps" slot-scope="scope">
                <!-- 自定义区域 -->
                <!-- use v-model="scope.model.gps" to bind data -->
                <div class="ml">
                    <div>
                        <p>GPS位置<i class="star"></i><b>:</b></p>
                        <van-field v-model="scope.model.gps"

                                   disabled
                                   label=""

                                 ></van-field>
                    </div>
                </div>

            </template>


            <template slot="households" slot-scope="scope">
                <!-- 自定义区域 -->
                <!-- use v-model="scope.model.households" to bind data -->
                <div class="ml">
                    <div>
                        <p>户数<i class="star"></i><b>:</b></p>
                        <van-field v-model="scope.model.households"
                                   label=""
                                   disabled
                        ></van-field>
                    </div>
                </div>
            </template>


            <template slot="imgArr" slot-scope="scope">
                <!--  -->
                <!-- use v-model="scope.model.imgArr" to bind data -->
                <p class="title"><i></i>图片上传（1-3张）</p>
                <div class="card-upload">
                    <van-uploader v-model="fileList"
                                  disabled
                                  :deletable="false"
                                  accept="image"
                                  :show-upload = false
                                  />
                </div>


            </template>
            <template slot="note" slot-scope="scope">
                <!--  -->
                <!-- use v-model="scope.model.note" to bind data -->
                <p class="title"><i></i>备注</p>
                <van-field
                        class="note"
                        v-model="scope.model.note"
                        rows="1"
                        autosize
                        maxlength="100"
                        label=""
                        readonly
                        type="textarea"
                        placeholder=""
                />

                <div class="linSpe"></div>

            </template>

            <template slot="buttInfoTitle" slot-scope="scope">
                <!--  -->
                <!-- use v-model="scope.model.buttInfoTitle" to bind data -->
                <p class="title"><i></i>对接信息</p>
            </template>

            <template slot="bankInfo" slot-scope="scope">
                <!--  -->
                <!-- use v-model="scope.model.bankInfo" to bind data -->
                <p class="title"><i></i>银行信息 </p>
                <div class="bankItems" v-for="(item,index) in bankArr" :key="index">
                    <p><label>{{item.bankName }} (距离{{item.bankDistance}}KM)</label></p>

                </div>


            </template>

        </fm-generate-form>

    </div>
</template>

<script>
    import areaList from "@/utils/area"
    import {
        createProbeTask,
        getAreaInfoCountyDict, getProbeTaskData, getSubTaskTemp,

    } from "../../utils/api";
    import common from "../../utils/common";
    import JsZip from "jszip";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        data () {
            let self = this
            return {
                jsonData: {
                    "list":[],
                    config:{}
                },

                editData: {
                },
                remoteFuncs: {

                    funcGetToken (resolve) {
                        // 图片上传 img
                        // Call callback function once get the token
                        // resolve(token)
                    },

                },

                addressShow: false,
                areaList,
                provinces:'',
                addressVal:'',
                countryShow:false,
                countryList:[],
                countryVal:'' ,
                gps:'',
                bankArr:[],
                isShowBankInput:false,
                bankName:'',
                bankDistance:'',
                fileList:[],
                uploadZipImgList:[],
                fileImgUrls:[],
                households:'',
                isNeedPic:false,
                taskCode:'probe',
                subTaskCode:'',
                taskId:'',

            }
        },
        filters:{

        },
        mounted() {
            this.subTaskCode = this.$route.query.subTaskCode
            this.taskId = this.$route.query.taskId

            let p = {
                taskCode:this.taskCode,
                subTaskCode:this.subTaskCode
            }
            getSubTaskTemp(p).then(res=>{
                if (res.code === 200 && res.data.businessCode === 200){
                    let json = res.data.data
                    json.list.forEach((item,index)=>{
                       item.options.disabled = true
                       item.options.placeholder = ''
                    })
                    this.jsonData = json
                    console.log("jsonData:",this.jsonData)
                }

            })
            let editDataP = {
                id:this.taskId
            }
            getProbeTaskData(editDataP).then(res=>{
                if (res.code === 200 && res.data.businessCode === 200){
                    this.editData = res.data.data
                    this.addressVal = this.editData.provinces
                    this.countryVal = this.editData.country
                    this.households = this.editData.households
                    this.fileImgUrls = this.editData.imgArr
                    this.saveAreaCode = this.editData.saveAreaCode
                    this.handlePic(this.fileImgUrls )
                    this.bankArr = JSON.parse(this.editData.bankInfo)
                    this.provincesAreaCode = this.editData.provincesAreaCode
                }
            })
            uwStatisticAction('/surveyDetail','走访调研查看只读')

        },
        methods: {
            handlePic(imgUrlArr){
                this.fileList = []
                console.log("网络图片：",imgUrlArr)
                for(let i= 0; i< imgUrlArr.length; i++){
                    if(process.env.NODE_ENV == 'production'){
                        let url = window.location.origin + '/file/' + imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')

                        this.fileList.push({url:url})
                    }else{
                        let url = 'http://124.70.167.116:20081' + '/file/' +  imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')
                        console.log("uel:",url)
                        // let url = window.location.origin + '/file/' + this.fileList[i] + '?Authorization=' + common.getItem('wtToken')
                        this.fileList.push({url:url})

                    }
                }
            }
        }
    }
</script>
<style lang="less" scoped>
    .surveyWarp{
        padding:  0 ;
        .title{
            margin-left: -95px;
            font-size: 17px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            height: 48px;
            line-height: 48px;
            i{
                width: 2px;
                height: 15px;
                display: inline-block;
                background: #3F7C53;
                border-radius: 1px;
                margin-right: 6px;

            }
            /*.star{
                width: 8px;
                height: 7px;
                display: inline-block;
                vertical-align: middle;
                background: url("../../assets/img/common/star.png");
                background-size: 100% 100%;
                margin-left: 2px;

            }*/
        }
        .ml{
            margin-left: -95px;
            width: 345px;
            height:48px ;
            div{
                display: flex;
                p{
                    display:inline-block;
                    width: 100px;
                    height: 48px;
                    line-height: 48px;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    b{
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                }
                label{
                    width: 100px;
                    display: inline-block;
                }
               /* .star{
                    width: 8px;
                    height: 7px;
                    display: inline-block;
                    vertical-align: middle;
                    background: url("../../assets/img/common/star.png");
                    background-size: 100% 100%;
                    margin-left: 2px;

                }*/
            }

        }
        .linSpe{
            margin-left: -110px;
            width: 375px;
            height:10px ;
            background:#f7f8f9;
        }
        .bankInfo{
            margin-top: 5px;
            margin-left: -95px;
            width: 345px;
            border-radius: 8px;
            border: 1px dashed #999999;
            text-align: center;
            padding: 12px;
            margin-bottom: 15px;
            label{
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
            };

        }
        .bankItems{
            margin-top: 5px;
            margin-left: -95px;
            width: 345px;
            margin-bottom: 15px;
            height: 58px;
            line-height: 58px;
            border-radius: 8px;
            border: 1px solid #999999;
            p{
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 15px;
                label{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #272829;
                }
                .close{
                   width: 30px;
                    height: 30px;
                    display: inline-block;
                    background: url("../../assets/img/common/close.png");
                    background-size: 100% 100%;
                }
            }
        }
        .card-upload{
            margin-left: -110px;
            padding: 15px;
        }
    }
    .fm-style{
        background: #ffffff;
        padding: 0;
    }
    .el-form-item__label{
        text-align: left;
    }

    .mapDiv{
        width:100%;
        height: 670px;
    }
    .BtnDiv{
        width: 100%;
        background: #ffffff;
        padding: 15px;
        .commitBtn{
            width: 100%;
            background: #3F7C53;
            border-radius: 4px;
            text-align: center;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            padding: 10px;
        }


    }
</style>
<style lang="less">
    .surveyWarp .el-form-item__label{
        text-align: left;
        width: 125px !important;
        padding-left: 15px;
        height: 48px;
        line-height: 48px;

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
    .surveyWarp .el-form-item{
        border-bottom: 1px solid #f7f8f9;
    }
    .surveyWarp  .el-form-item__content{
        margin-left: 110px !important; ;
    }
    .surveyWarp .van-cell,.van-field__control,van-cell__value ,.van-cell__value--alone ,.van-field__value {
        height: 48px !important;
        line-height: 48px !important;
        padding: 0;
    }
    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
        margin: 0;
    }
    .surveyWarp .el-input{
        width: calc(100% - 15px) !important;
        padding-right: 15px;
        height: 48px;
        text-align: right;
        line-height: 48px;
    }
    .surveyWarp .el-input .el-input__inner,.surveyWarp .van-field__control{
        padding: 0;
        text-align: right;
        border: none;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(84,84,84,1) !important;
    }
    .surveyWarp input::-webkit-input-placeholder,.surveyWarp textarea::-webkit-input-placeholder{   /* 使用webkit内核的浏览器 */

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .surveyWarp input:-moz-placeholder,.surveyWarp textarea:-moz-placeholder{    /* Firefox版本4-18 */

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .surveyWarp input::-moz-placeholder,.surveyWarp textarea::-moz-placeholder{    /* Firefox版本19+ */

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .surveyWarp input:-ms-input-placeholder,.surveyWarp textarea:-ms-input-placeholder{   /* IE浏览器 */

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }


    .surveyWarp{
        .note {
            margin-left: -95px;
            width: 345px;
            height: auto !important;
            line-height: normal !important;
            min-height: 110px;

        }
        .note .van-field__control{

            text-align: left;
            line-height: normal !important;
            min-height: 100px;
            padding: 10px  0;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }
        .van-dialog{

            border-radius: 8px;
        }
        .van-dialog__header{
            padding-top: 20px;
        }
        .van-dialog__content{
            padding: 0 15px;
            .van-field__label,.van-field__control{

                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333 !important;

            }
            .van-field__label{

                width: 140px;
            }

        }
        .van-dialog__cancel .van-button__text{

            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #B5BBC9;


        }
        .van-dialog__confirm .van-button__text{

            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3F7C53;

        }
        .el-input.is-disabled .el-input__inner{
            background: #ffffff;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333 !important;
            cursor: none;

        }
        .van-uploader__wrapper--disabled{
            opacity: 1;
        }
        .el-input.is-disabled .el-input__inner{
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333 !important;
        }
        .van-field__control:disabled,.el-input.is-disabled:disabled{
            opacity: 1;
        }
        .el-input.is-disabled:disabled{
            opacity: 0;
        }
        input:disabled, input[disabled]{
            color:#333333 !important;
            -webkit-text-fill-color:#333333 !important;
            -webkit-opacity:1 !important;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
        }

    }

</style>